document.addEventListener("turbo:before-stream-render", function(event) {
  switch (event.target.getAttribute("action")) {
    case 'append':
    case 'prepend':
    case 'after':
    case 'before':
      const template = event.target.templateElement.content;
      for (let i = 0; i < template.children.length; i++) {
        const element = template.children[i];
        if (element instanceof HTMLElement && element.dataset.turboInsertClass) {
          const classes = element.dataset.turboInsertClass.split(' ');
          element.classList.add(...classes);
          element.addEventListener("animationend", function() {
            element.classList.remove(...classes);
          }, { once: true });
        }
      }
      break;
    case 'remove':
      const promises = [];
      event.target.targetElements.forEach(element => {
        if (element instanceof HTMLElement && element.dataset.turboRemoveClass) {
          promises.push(new Promise(resolve => {
            const classes = element.dataset.turboRemoveClass.split(' ');
            const insertClass = element.dataset.turboInsertClass;

            // We must remove the insert animation if it's present
            if (insertClass) {
              element.classList.remove(...insertClass.split(' '));
            }

            element.classList.add(...classes);
            element.addEventListener("animationend", function() {
              resolve();
            }, { once: true });
          }));
        }
      })

      if (promises.length > 0) {
        // Intercept the removal of the element
        event.preventDefault()

        // Await all animationend events firing, or 400ms, whichever comes first
        // Then execute the remove stream action function.
        const timeout = new Promise(resolve => { setTimeout(resolve, 1000) })
        Promise.any([Promise.all(promises), timeout]).then(() => {
          event.target.performAction()
        });
      }
      break;

    default:
  }
})
