import { Controller } from "@hotwired/stimulus"

/**
 * Base class for Stimulus controllers that provides a few convenience methods.
 */
export default class extends Controller {
  private _eventListeners = []

  /**
   * Remove all event listeners when the controller disconnects.
   * Remember to call super.disconnect() if you override this method.
   */
  disconnect() {
    this._eventListeners.forEach(({ on, event, callback }) => {
      on?.removeEventListener(event, callback)
    });
  }

  /**
   * Add an event listener to the element, and automatically remove it when the controller disconnects.
   * @param on The element to add the event listener to.
   * @param event
   * @param callback
   * @param options
   */
  addEventListener(on: any, event: string, callback: (event: Event) => void, options: AddEventListenerOptions = {}) {
    // Support addEventListener('event', callback, {}) syntax
    if (typeof on === 'string' && typeof event === 'function') {
      [on, event, callback, options] = [this.element, on, event, callback as any || options]
    }
    on.addEventListener(event, callback, options)
    this._eventListeners.push({ on, event, callback })
  }

  /**
   * Find the given controller on the closest parent element.
   *
   * @param identifier The controller identifier
   * @param element The element to find the controller on. Defaults to the closest parent element with the data-controller attribute.
   * @returns The controller instance, or null if not found.
   */
  parentController(identifier) {
    return this.getController(this.element.closest(`[data-controller~=${identifier}]`), identifier);
  }

  /**
   * Find the given controller on the closest child element.
   *
   * @param identifier The controller identifier
   * @returns The controller instance, or null if not found.
   */
  childController(identifier) {
    return this.getController(this.element.querySelector(`[data-controller~=${identifier}]`), identifier);
  }

  /**
   * Get the controller for the given element and identifier.
   *
   * @param element The element to get the controller for.
   * @param identifier The controller identifier.
   * @raises If the controller is not found.
   * @returns The controller instance.
   */
  getController(element, identifier) {
    const controller = this.application.getControllerForElementAndIdentifier(
      element,
      identifier
    )
    if (!controller) {
      console.error("Controller", identifier, "not found on", element);
      throw new Error(`Controller ${identifier} not found on ${element}`);
    }
    return controller;
  }
}
