import Controller from './application_controller'

const EVENTS = [
  'turbo:before-fetch-request',
  'turbo:fetch-request-error',
  'turbo:frame-load',
  'turbo:frame-missing',
  'turbo:frame-render',
  'turbo:submit-end'
]

const DEFAULT_LOADING_CLASS = 'turbo-loading'

/**
 * There is a bug in Turbo Frames that turns off the busy/loading status before the response
 * body has been downloaded and applied. On slow connections, this makes animation/visual changes
 * a bit janky.
 *
  * This controller listens for Turbo Frame events and adds/removes a loading class to the element
 */
export default class extends Controller {
  static classes = ["loading"]

  connect() {
    EVENTS.forEach(type => this.addEventListener(type, event => this.handleLoadingEvent(event)))
  }

  handleLoadingEvent(event) {
    const loadingClass = this.hasLoadingClass ? this.loadingClass : DEFAULT_LOADING_CLASS
    switch (event.type) {
      case 'turbo:before-fetch-request':
        this.element.classList.add(loadingClass)
        break
      case 'turbo:fetch-request-error':
      case 'turbo:frame-load':
      case 'turbo:frame-render':
      case 'turbo:frame-missing':
      case 'turbo:submit-end':
        this.element.classList.remove(loadingClass)
        break
    }
  }
}