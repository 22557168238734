// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import 'lib/cable'
import 'lib/turbo-stream-animations'
import 'lib/choices-select'

import 'controllers'
import '../components'

Turbo.config.drive.progressBarDelay = 150
