import Controller from "./application_controller"

/**
 * Handles the initialization of a legacy behaviour class wrapper on the
 * element.
 */
export default class extends Controller {
  connect() {
    this.element[this.behaviourKey] ??= new (this.behaviourConstructor)(this.element);
  }

  get behaviourName() {
    return this.data.get('type');
  }

  get behaviourKey() {
    return `__LegacyBehaviourController__${this.behaviourName}`;
  }

  get behaviourConstructor() {
    const { behaviourName } = this;
    if (typeof window[behaviourName] === 'function') {
      return window[behaviourName]
    }

    throw new Error(`Could not find constructor for Legacy Behaviour: ${behaviourName}`);
  }
}
