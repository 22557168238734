import { Application } from "@hotwired/stimulus"
import { identifierForContextKey } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()

/**
 * A custom definitionsFromContext that loads controllers in the Webpack context,
 * but applies an explicit `identifier` if defined on the Controller class.
 * @param {} context Webpack context
 */
export function definitionsFromContext(context) {
  return context.keys().map(key => {
    const controllerConstructor = context(key).default

    if (typeof controllerConstructor == "function") {
      // Allow the controller to explicitly define its identifier
      const identifier = controllerConstructor.identifier || identifierForContextKey(key)
      application.load({ identifier, controllerConstructor })
    }
  }).filter(definition => definition)
}

export default application
