import Controller from "controllers/application_controller"
import { getComputedStyles, setElementStyle } from "lib/element"
import {autoUpdate, computePosition, flip, offset} from '@floating-ui/dom';

class ProjectTaskSearchService {
  constructor(contactId) {
    this.projects = window.projects;
    this.tasks = window.tasks;

    if (contactId) {
      this.projects = this.projects.filter(project => project.contact_id == contactId);
    }
  }

  search(query, { limit = 12 } = {}) {
    let projectResults = this.projects.filter(result => {
      return result.short_code.toLowerCase().includes(query.toLowerCase())
        || result.name.toLowerCase().includes(query.toLowerCase());
    }).slice(0, limit);

    let tasksResults = this.tasks.filter(result => {
      return result.short_code.toLowerCase().includes(query.toLowerCase())
        || result.name?.toLowerCase().includes(query.toLowerCase());
    }).slice(0, limit);

    if ((projectResults.length + tasksResults.length) > 10) {
      const countProjects = limit - Math.min(Math.floor(limit/2), tasksResults.length);
      const countTasks = limit - Math.min(Math.ceil(limit/2), projectResults.length);
      projectResults = projectResults.slice(0, countProjects)
      tasksResults = tasksResults.slice(0, countTasks)
    }

    return [...projectResults, ...tasksResults];
  }
}


class ContactSearchService {
  constructor() {
    this.contacts = window.contacts;
  }

  search(query, { limit = 12 } = {}) {
    return this.contacts.filter(result => {
      return result.short_code.toLowerCase().includes(query.toLowerCase())
        || result.name.toLowerCase().includes(query.toLowerCase());
    }).slice(0, limit);
  }
}

export default class extends Controller {
  static identifier = 'entry--description'
  static targets = ['input', 'search', 'searchTemplate']

  input;

  searchController;

  mirror;
  mirrorBefore;
  mirrorSearch;
  mirrorCursor;
  mirrorAfter;

  tBeforeSearch;
  tSearchToken;
  tSearch;
  tSuffix;

  lastValue;
  lastSelectionStart;
  lastSelectionEnd;
  lastScrollLeft;

  get entryController() {
    return this.parentController('entry');
  }

  connect() {
    super.connect()
    this.createMirrorElement();

    this.addEventListener(this.inputTarget, 'keydown', this.onKeyDown.bind(this))
    this.addEventListener(this.inputTarget, 'beforeinput', this.onBeforeInput.bind(this))

    // NB: We add the tracking events first, so they run before behaviour events
    //     that might depend on the current state.
    this.addEventListener(document, 'selectionchange', this.trackCursorAndTokens.bind(this));

    ['input', 'change', 'click', 'keydown', 'keyup', 'cut', 'paste', 'focus', 'blur-sm'].forEach(event => {
      this.addEventListener(this.inputTarget, event, this.trackCursorAndTokens.bind(this))
    });

    this.addEventListener(this.inputTarget, 'focusout', this.detachSearch.bind(this))
  }

  disconnect() {
    this.mirror.remove();
    super.disconnect()
  }

  inputTargetConnected(input) {
    // Store the input element directly, to allow accessing outside of the
    // Stimulus lookup methods (since these do a querySelector lookup).
    // Since we are only ever connected to one input, this is safe.
    // We do this because we access the value on every keystroke/interaction
    // event on the input, so it needs to be handled performantly.
    this.input = input;
  }

  searchTargetConnected(search) {
    this.searchController = this.getController(search, 'entry--search')
    this.searchController.delegate = this
    this.searchController.searchService = this.createSearchService();

    this.updateSearch()
  }

  searchTargetDisconnected() {
    this.searchController = null;
  }

  createSearchService() {
    if (this.tSearchToken == '@') {
      return new ContactSearchService();
    } else {
      return new ProjectTaskSearchService(this.entryController.contactId)
    }
  }

  didAcceptSearchResult(result) {
    let resultValue = `${this.tSearchToken}${result.short_code}`;

    // We will replace tSearch with resultValue and put the cursor at the end
    // But first check if after the cursor has the suffix of the selected result
    // so that we can consume that too.
    let prefix = this.tBeforeSearch
    let search = this.tSearch
    let suffix = this.tSuffix;

    if (resultValue.length > search.length && resultValue.startsWith(search)) {
      const resultSuffix = resultValue.slice(search.length);

      let commonPrefixLength = 0;
      while ( commonPrefixLength < resultSuffix.length
              && commonPrefixLength < suffix.length
              && suffix[commonPrefixLength] === resultSuffix[commonPrefixLength]) {
        commonPrefixLength++;
      }

      if (commonPrefixLength > 0) {
        suffix = suffix.slice(commonPrefixLength);
      }
    }

    let spaceBefore = '', spaceAfter = ' '; // always ensure a spaceAfter
    if (prefix.length > 0 && !prefix.endsWith(' ')) {
      spaceBefore = ' ';
    }
    if (suffix.startsWith(' ')) {
      suffix = suffix.slice(1);
    }

    // For Projects/Tasks, insert them in the text field
    // For Contacts, update the <select> element
    let newValue;
    if (result.contact_id) {
      // Project
      this.entryController.contactId = result.contact_id;
    } else if (result.has_detail === undefined) {
      // Contact
      this.entryController.contactId = result.id;
    }

    if (this.tSearchToken == '#') {
      newValue = [prefix, spaceBefore, resultValue, spaceAfter, suffix].join('');
    } else {
      newValue = [prefix, spaceBefore, suffix].join('');
    }

    this.inputTarget.value = newValue;
    this.inputTarget.focus();
    this.inputTarget.selectionStart = newValue.length - suffix.length;
    this.inputTarget.selectionEnd = newValue.length - suffix.length;
    this.detachSearch();
  }

  onKeyDown(event) {
    if (!this.searchController) {
      return;
    }

    switch(event.key) {
      case "Escape":
        this.detachSearch();
      break;

      case "Tab":
      case "Enter":
        if (this.searchController.selectedResult) {
          event.preventDefault();
          this.didAcceptSearchResult(this.searchController.selectedResult);
        } else if (event.key === "Enter") {
          this.dispatch('app:request-submit', { prefix: false, bubbles: true });
        }
      break;

      case "ArrowUp":
      case "ArrowDown":
        this.searchController.selectNextResult(event.key == "ArrowDown");
        event.preventDefault();
      break;
    }
  }

  onBeforeInput(event) {
    if (event.data == " " && this.searchController?.selectedResult) {
      event.preventDefault();
      this.didAcceptSearchResult(this.searchController.selectedResult);
    }
  }

  attachSearch() {
    if (this.hasSearchTarget) {
      this.searchTarget.remove();
    }

    const fragment = this.searchTemplateTarget.content.cloneNode(true);
    this.element.append(fragment);
  }

  detachSearch() {
    if (this.hasSearchTarget) {
      this.searchTarget.remove();
    }
  }

  anchorSearchPosition() {
    computePosition(this.mirrorSearch, this.searchTarget, {
      placement: 'bottom-start'
    }).then(({x, y}) => {
      Object.assign(this.searchTarget.style, {
        position: 'absolute',
        left: `${x}px`,
        top: `${y}px`
      })
    });
  }

  updateSearch() {
    if (this.tSearch) {
      if (this.searchController) {
        this.syncMirror();
        this.anchorSearchPosition();

        if (this.tSearchToken == '#' && !(this.searchController.searchService instanceof ProjectTaskSearchService)
            || this.tSearchToken == '@' && !(this.searchController.searchService instanceof ContactSearchService)) {
          this.searchController.searchService = this.createSearchService();
        }

        this.searchController.updateQuery(this.tSearch.slice(1));
      } else {
        this.attachSearch();
      }
    } else if (this.searchController) {
      this.detachSearch();
    }
  }

  /**
   * Keeps a running track of the current cursor position and the relevant
   * tokens before and after the cursor, taking into account the "search" token.
   *
   * @param {*} event
   * @returns
   */
  trackCursorAndTokens(event) {
    const value          = this.input.value,
          selectionStart = this.input.selectionStart,
          selectionEnd   = this.input.selectionEnd,
          scrollLeft     = this.input.scrollLeft;

    if (value          === this.lastValue &&
        selectionStart === this.lastSelectionStart &&
        selectionEnd   === this.lastSelectionEnd &&
        scrollLeft     === this.lastScrollLeft) {
      return;
    }

    this.lastValue = value
    this.lastSelectionStart = selectionStart
    this.lastSelectionEnd = selectionEnd
    this.lastScrollLeft = scrollLeft

    const prefix = this.lastValue.slice(0, this.lastSelectionStart)
    const suffix = this.lastValue.slice(this.lastSelectionEnd);

    const lastSearchIndex = Math.max(...['@', '#'].map(char => prefix.lastIndexOf(char)));
    const lastWhitespaceIndex = prefix.lastIndexOf(" ");
    let beforeSearch
    let search
    let searchToken

    if (lastSearchIndex !== -1 && lastSearchIndex > lastWhitespaceIndex) {
      beforeSearch = prefix.slice(0, lastSearchIndex);
      search = prefix.slice(lastSearchIndex);
      searchToken = search[0];
    } else {
      beforeSearch = prefix;
      search = null;
      searchToken = null;
    }

    this.tBeforeSearch = beforeSearch;
    this.tSearch = search;
    this.tSuffix = suffix;
    this.tSearchToken = searchToken;

    this.updateSearch();
  }

  /**
   * Updates the mirror element to reflect the current cursor position and tokens.
   * This allows precise screen positioning of the relevant tokens and cursor.
   *
   * @param {*} event
   */
  syncMirror(event) {
    setElementStyle(this.mirror, this.calculateMirrorPosition());

    this.mirrorBefore.innerText = this.tBeforeSearch
    this.mirrorSearch.innerText = this.tSearch || "\u200B"
    this.mirrorAfter.innerText = this.tSuffix
    this.mirror.scrollLeft = this.lastScrollLeft
  }

  /**
   * Sets up a mirror element that tracks the content and cursor position of
   * the input field. This mirror is used to accurately calculate the position
   * of the cursor and tokens on the screen.
   */
  createMirrorElement() {
    const rect = this.inputTarget.getBoundingClientRect();
    const offsetRect = this.inputTarget.offsetParent.getBoundingClientRect();

    this.mirror = document.createElement('div');
    this.mirror.append(
      this.mirrorBefore = document.createElement('span'),
      this.mirrorSearch = document.createElement('span'),
      this.mirrorCursor = document.createElement('span'),
      this.mirrorAfter = document.createElement('span')
    );
    this.mirrorCursor.innerHTML = "&ZeroWidthSpace;";

    setElementStyle(this.mirror, {
      ...getComputedStyles(this.inputTarget,
        ['font-family', 'font-size', 'line-height', 'letter-spacing',
          'word-spacing', 'padding', 'border-width', 'border-style',
          'white-space', 'background-color', 'color']
      ),
      position: 'absolute',
      'box-sizing': 'border-box',
      ...this.calculateMirrorPosition(),
      visibility: 'hidden',
      'user-select': 'none',
      'pointer-events': 'none',
      'overflow-x': 'hidden'
     });

    this.syncMirror();
    this.inputTarget.insertAdjacentElement('beforebegin', this.mirror);
  }

  calculateMirrorPosition() {
    const rect = this.inputTarget.getBoundingClientRect();
    const offsetRect = this.inputTarget.offsetParent.getBoundingClientRect();
    return {
      top: `${rect.top - offsetRect.top}px`,
      left: `${rect.left - offsetRect.left}px`,
      width: `${rect.width}px`,
      height: `${rect.height}px`,
    }
  }

}
