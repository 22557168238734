import Controller from "controllers/application_controller"

const Comparators = {
  string: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
  number: (a, b) => parseInt(a) - parseInt(b)
}

// function longestOrderedSequence(a, b) {
//   // Array A original order
//   // Array B sorted order
//   // Find longest sequence of indexes i..j where IndexA(i) < IndexA(j) and IndexB(i) < IndexB(j)

//   return indexes
// }

export default class extends Controller {
  static identifier = 'ui-table'
  static targets = ['heading', 'body']

  sortColumn;
  direction;

  sort(event) {
    let heading = event.target.closest('[data-ui-table-target="heading"]')
    let column = this.headingTargets.indexOf(heading)
    let sortType = heading.dataset.sortType || 'string'
    let comparator = Comparators[sortType]
    let rows = Array.from(this.bodyTarget.rows)

    // Ignore if nothing to sort
    if (rows.length < 2) {
      return
    }

    // Clear sorted state for all headings
    this.headingTargets.forEach(heading => {
      delete heading.dataset.sorted
    })

    if (this.sortColumn === column) {
      this.direction = this.direction === 1 ? -1 : 1
    } else {
      this.sortColumn = column
      this.direction = 1
    }

    heading.dataset.sorted = this.direction > 0 ? 'asc' : 'desc'

    let sortedRows = rows.sort((r0, r1) => {
      const c0 = r0.children[column]
      const c1 = r1.children[column]
      return comparator(
        c0.dataset.value ?? c0.innerText,
        c1.dataset.value ?? c1.innerText
      ) * this.direction
    })

    sortedRows.forEach((row, index) => {
      this.bodyTarget.appendChild(row)
    })
  }
}
