import  { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    min: String
  }

  calendar = null;

  connect() {
    this.calendar = this.setupCalendarInstance();
  }

  disconnect() {
    // FIXME: Doesn't appear to be a way to remove these from the DOM
  }

  // TODO: Use a more modern calendar selector.
  setupCalendarInstance() {
    var minDate;
    if (this.hasMinValue) {
      minDate = new Date(this.minValue);
      minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate());
    }

    Calendar.setup({
      dateField      : this.element,
      dateFormat     : '%b %e %Y',
      markFuture     : true,
      minDate        : minDate,
      selectHandler  : calendar => {
        if (calendar.date > new Date()) {
          // Allow picking future dates
        } else if (minDate && calendar.date < minDate) {
          calendar.shouldClose = false;
          return false;
        }

        Calendar.defaultSelectHandler(calendar);
        this.dispatch('change', { prefix: false });
      }
    });
  }
}
